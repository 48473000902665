let _this = null
const defaultExamine = {
  width: 200,
  height: 200
}
export default {
  install(Vue, option) {
    _this = option
    Vue.prototype.$examineImgWH = examineImgWH
    Vue.prototype.$examineImgDirection = examineImgDirection
  }
}

const examineImgWH = (file, examine = defaultExamine) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const image = new Image()
      image.src = reader.result
      // 判断是否有缓存
      if (image.complete) {
        WH(image, examine, resolve, reject)
      } else {
        // 加载完成执行
        image.onload = () => {
          WH(image, examine, resolve, reject)
        }
      }
    }
  })

const WH = function(image, examine, resolve, reject) {
  // 上传图片前检查图片宽高
  if (image.width > examine.width || image.height > examine.height) {
    _this.$message.error(
      `请上传宽高尺寸为${examine.width} x ${examine.height}的图片`
    )
    reject(false)
  }
  resolve(true)
}

const examineImgDirection = (
  file,
  examine,
  resolve, // eslint-disable-line
  reject // eslint-disable-line
) =>
  new Promise((resolve, reject) => {
    // 判断图片方向，默认横向
    const reader = new FileReader() // eslint-disable-line
    reader.readAsDataURL(file)
    reader.onload = () => {
      const image = new Image() // eslint-disable-line
      image.src = reader.result
      // 判断是否有缓存
      if (image.complete) {
        direction(image, examine, resolve, reject)
      } else {
        // 加载完成执行
        image.onload = () => {
          direction(image, examine, resolve, reject)
        }
      }
    }
  })

const direction = function(image, examine, resolve, reject) {
  // 判断图片方向
  if (examine === 'horizontal') {
    // 横
    if (image.width < image.height || image.width === image.height) {
      _this.$message.error('请上传横向方向的图片')
      reject(false)
    }
  }
  if (examine === 'vertical') {
    // 竖
    if (image.width > image.height || image.width === image.height) {
      _this.$message.error('请上传竖向方向的图片')
      reject(false)
    }
  }
  resolve(true)
}
