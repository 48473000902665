import axios from '../axios/api'
const version = '3.0.3'

;(function versionUpdate() {
  const data = {
    value: version
  }
  axios.versionUpdate(data).then((res) => {
    if (!res.data.data) {
      // 刷新
      window.location.replace(window.location.origin)
      window.location.reload()
    }
  })
})()
