const routerPaths = {
  admin: '/admin',
  shop: '/shopAdmin',
  proxy: '/proxyAdmin',
  salesman: '/salesmanAdmin',
  follower: '/followerAdmin'
}

const pageButtons = [
  {
    value: 'add',
    label: '新增',
    checked: false
  },
  {
    value: 'delete',
    label: '删除',
    checked: false
  },
  {
    value: 'edit',
    label: '编辑',
    checked: false
  },
  {
    value: 'search',
    label: '搜索',
    checked: false
  },
  {
    value: 'info',
    label: '详情页',
    checked: false
  },
  {
    value: 'export',
    label: '导出',
    checked: false
  },
  {
    value: 'switch',
    label: '禁用/启用/上线/下线',
    checked: false
  },
  {
    value: 'cancel',
    label: '取消',
    checked: false
  },
  {
    value: 'manage',
    label: '其他管理',
    checked: false
  }
]
const getBase64 = function(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

import router from '../router'
const handleHeadBack = function() {
  router.go(-1)
}

const jump = function(e) {
  let value = {
    path: e.path
  }
  if (e.query) {
    value.query = e.query
  }
  router.push(value)
}

export default {
  install(Vue) {
    Vue.prototype.$routerPaths = routerPaths
    Vue.prototype.$getBase64 = getBase64
    Vue.prototype.$handleHeadBack = handleHeadBack
    Vue.prototype.$jump = jump
    Vue.prototype.$pageButtons = pageButtons
  }
}
