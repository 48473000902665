/**
 * 接口列表
 */

import base from './base' // 导入接口域名列表
import axios from './http' // 导入http中创建的axios实例
const api = {
  versionUpdate(params) {
    return axios.post(`${base.main}admin/Versions/Version`, params)
  },
  login(params) {
    return axios.post(`${base.main}admin/login/login`, params)
  },
  wechatLogin() {
    return axios.post(`${base.second}admin/login/needData`)
  },
  getAllRoleMenuControl() {
    // 获取所有角色的菜单管理（非展开）
    return axios.post(`${base.main}admin/permissions/menu`)
  },
  getAllRoleMenuControls: () =>
    // 获取所有角色的菜单管理（展开）
    new Promise((resolve) => {
      axios
        .post(`${base.main}admin/permissions/menus`)
        .then((res) => {
          const rows = res.data.data
          window.sessionStorage.setItem('allMenuArr', JSON.stringify(rows))
          formatPurview(rows)
        })
        .catch((res) => {
          console.log(res)
        })
      function formatPurview(arr) {
        // 格式化权限列表
        const array = {}
        const returnArr = []
        arr.forEach((item) => {
          item.parent_ids = []
          array[item.id] = item
        })
        arr.forEach((item) => {
          item.indeterminate = false
          item.checked = false
          item.parent_ids = []
          pushItem()

          function pushItem() {
            if (item.parent_id !== 0) {
              if (array[item.parent_id]) {
                ; (
                  array[item.parent_id].children ||
                  (array[item.parent_id].children = [])
                ).push(item)
                // 保存上级id
                item.parent_ids.push(item.parent_id)
                item.parent_ids = [
                  ...item.parent_ids,
                  ...array[item.parent_id].parent_ids
                ]
              }
            } else {
              returnArr.push(item)
            }
          }
        })
        window.sessionStorage.setItem('menuFormat', JSON.stringify(returnArr))
        resolve(returnArr)
      }
    }),
  addAllRoleMenuControl(params) {
    // 新增所有角色的菜单管理
    return axios.post(`${base.main}admin/permissions/insertMenu`, params)
  },
  editAllRoleMenuControl(params) {
    // 编辑所有角色的菜单管理
    return axios.post(`${base.main}admin/permissions/editmenu`, params)
  },
  delAllRoleMenuControl(params) {
    // 删除所有角色的菜单管理
    return axios.post(`${base.main}admin/permissions/deletemenu`, params)
  },
  getRoleBasedAccessControl() {
    // 获取基于角色的权限管理
    return axios.post(`${base.main}admin/permissions/role`)
  },
  addRole(params) {
    // 新建角色
    return axios.post(`${base.main}admin/permissions/insertRole`, params)
  },
  delRole(params) {
    // 删除角色
    return axios.post(`${base.main}admin/permissions/delRole`, params)
  },
  addRoleLevel(params) {
    // 新建角色等级
    return axios.post(`${base.main}admin/permissions/insertRoleLevel`, params)
  },
  getRoleLevelMenu(params) {
    // 获取角色等级拥有的菜单列表
    return axios.post(
      `${base.main}admin/permissions/thelevelpermission`,
      params
    )
  },
  baseRoleLevelMenu(params) {
    // 保存角色等级拥有的菜单列表
    return axios.post(
      `${base.main}admin/Permissions/roleLevelPermission`,
      params
    )
  },
  getAccount(params) {
    // 获取账户列表
    return axios.post(`${base.main}admin/Madmin/account`, params)
  },
  newAccountNumber(params) {
    // 新增账户
    return axios.post(`${base.main}admin/Madmin/create`, params)
  },
  getAccountTypeData() {
    // 获取账户类型列表
    return axios.post(`${base.main}admin/Madmin/accounttype`)
  },
  getWechatBindLink(params) {
    // 获取绑定微信链接
    return axios.post(`${base.main}admin/common/need`, params)
  },
  wechatBind(params) {
    // 绑定微信
    return axios.post(`${base.main}admin/common/bindOpenid`, params)
  },
  getArea: () =>
    // 获取区域列表
    new Promise((resolve) => {
      axios.post(`${base.main}admin/Madmin/areaData`).then((res) => {
        if (res.status === 200) {
          const rows = res.data.data
          repeat(rows)
        }
      })
      function repeat(arr) {
        // 重组数组
        const treeArr = []
        arr.forEach((item) => {
          // 重组字段
          const data = {
            id: item.id,
            name: item.name,
            parent_id: item.parent_id
          }
          treeArr.push(data)
        })
        format(treeArr)
      }
      function format(arr) {
        // 格式化列表
        const array = {}
        const returnArr = []
        arr.forEach((item) => {
          array[item.id] = item
        })
        arr.forEach((item) => {
          if (item.parent_id !== 0) {
            ; (
              array[item.parent_id].children ||
              (array[item.parent_id].children = [])
            ).push(item)
          } else {
            returnArr.push(item)
          }
        })
        resolve(returnArr)
      }
    }),
  addArea(params) {
    // 新增区域
    return axios.post(`${base.main}admin/area/create`, params)
  },
  editArea(params) {
    // 修改区域名称
    return axios.post(`${base.main}admin/area/save`, params)
  },
  getProxys() {
    // 获取代理企业列表
    return axios.post(`${base.main}admin/Madmin/proxyData`)
  },
  getAccounUnrole(params) {
    // 获取未分配角色的账号
    return axios.post(`${base.main}admin/Madmin/accounUnrole`, params)
  },
  distributeAccountRole(params) {
    // 分配角色
    return axios.post(`${base.main}admin/Madmin/accountRole`, params)
  },
  // 常驻业务线配置-start
  getBusinessConfig(params) {
    // 获取常驻业务线配置
    return axios.post(`${base.main}admin/Businessrole/index`, params)
  },
  getActivityConfig(params) {
    // 获取支线业务线配置（活动）
    return axios.post(`${base.main}admin/Activitie/activities`, params)
  },
  getRoleData(params) {
    // 保存用于选项的角色数据（例如新建其他需要绑定角色的项）
    return axios.post(`${base.main}admin/Businessrole/roseRate`, params)
  },
  getNormalConfig(params) {
    // 获取常态分成比例配置
    return axios.post(`${base.main}admin/Businessrole/bcRoselist`, params)
  },
  getStarConfig(params) {
    // 获取星级源数据
    return axios.post(`${base.main}admin/Businessrole/bcLevelData`, params)
  },
  getNormalStarConfig(params) {
    // 获取对应角色的星级数据
    return axios.post(`${base.main}admin/Businessrole/bcroseListRate`, params)
  },
  editNormalStarConfig(params) {
    // 修改对应角色的星级数据
    return axios.post(`${base.main}admin/Businessrole/editListLevel`, params)
  },
  addNormalConfig(params) {
    // 新增常态分成比例配置
    return axios.post(`${base.main}admin/Businessrole/addrole`, params)
  },
  saveNormalConfig(params) {
    // 保存编辑常态分成比例配置
    return axios.post(`${base.main}admin/Businessrole/editrole`, params)
  },
  addNormalSubConfig(params) {
    // 新增常态分成二级比例配置
    return axios.post(
      `${base.main}admin/Businessrole/addSecondRoleRate`,
      params
    )
  },
  saveNormalSubConfig(params) {
    // 保存编辑常态分成比例配置
    return axios.post(`${base.main}`, params)
  },
  delNormalConfig(params) {
    // 删除常态分成比例配置(普通)
    return axios.post(`${base.main}admin/Businessrole/delBcRole`, params)
  },
  delNormalStarConfig(params) {
    // 删除常态分成比例配置(星级)
    return axios.post(`${base.main}admin/Businessrole/delevelBcRole`, params)
  },
  getFollowConfig(params) {
    // 获取随动分成比例配置
    return axios.post(
      `${base.main}admin/Businessrole/businessRoleSpread`,
      params
    )
  },
  getFollowStarConfig(params) {
    // 获取对应角色的星级数据
    return axios.post(`${base.main}admin/Businessrole/bcSpreadListRate`, params)
  },
  editFollowStarConfig(params) {
    // 修改对应角色的星级数据
    return axios.post(
      `${base.main}admin/Businessrole/editSpreadListLevel`,
      params
    )
  },
  addFollowConfig(params) {
    // 新增随动分成比例配置
    return axios.post(
      `${base.main}admin/Businessrole/createBusinessRoleSpread`,
      params
    )
  },
  saveFollowConfig(params) {
    // 保存随动分成比例配置（二级共用）
    return axios.post(`${base.main}admin/Businessrole/editSpreadRole`, params)
  },
  delFollowConfig(params) {
    // 删除随动分成比例配置(普通)
    return axios.post(`${base.main}admin/Businessrole/delSpredrole`, params)
  },
  delFollowStarConfig(params) {
    // 删除随动分成比例配置(星级)
    return axios.post(`${base.main}admin/Businessrole/delevelSpredrole`, params)
  },
  addFollowSubConfig(params) {
    // 新增随动分成二级比例配置
    return axios.post(
      `${base.main}admin/Businessrole/addSecondSpredRoleRate`,
      params
    )
  },
  // 常驻业务线配置-end
  getAccountType(params) {
    // 获取系统账号类型
    return axios.post(`${base.main}admin/System/index`, params)
  },
  getAccountStarConfig(params) {
    // 获取系统账号星级配置
    return axios.post(`${base.main}admin/System/lindex`, params)
  },
  addAccountStarConfig(params) {
    // 新增系统账号星级配置
    return axios.post(`${base.main}admin/System/createLevel`, params)
  },
  editAccountStarConfig(params) {
    // 更改系统账号星级配置
    return axios.post(`${base.main}admin/System/editLevel`, params)
  },
  delAccountStarConfig(params) {
    // 删除系统账号星级配置
    return axios.post(`${base.main}admin/System/delLevel`, params)
  },
  handleUpload(params) {
    const formData = new FormData() // eslint-disable-line
    params.forEach((file) => {
      formData.append('file', file)
    })
    return axios.post(`${base.main}/`, formData)
  }
}

export default api
