<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN' // 中文语言包
export default {
  data() {
    return {
      locale: zhCN
    }
  },
  created() {
    this.$store.commit(
      'changeToken',
      window.sessionStorage.getItem('token') || ''
    )
    this.$store.commit(
      'changeQiniuToken',
      window.sessionStorage.getItem('qiniuToken') || {}
    )
  }
}
</script>
<style>
body {
  font-family: 'PingFang SC', 'Microsoft YaHei', 'Helvetica Neue', Helvetica,
    Arial;
}
</style>
