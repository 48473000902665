export default {
  changeToken(state, provider) {
    state.token = provider
  },
  changeQiniuToken(state, provider) {
    state.qiniuToken = provider
  },
  changePageTitle(state, provider) {
    state.pageTitle = provider
  }
}
