<template>
  <a-menu
    theme="dark"
    mode="inline"
    :inlineIndent="16"
    v-model="sideIndex"
    :open-keys="openKeys"
    :inline-collapsed="collapsed"
    @click="menuSelect"
    @openChange="openChange"
  >
    <template v-for="item in sidebar">
      <a-menu-item v-if="!item.children" :key="item.id" :value="item.url">
        <!-- <a-icon type="ant-design" /> -->
        <my-icon type="icon-qingchu" />
        <span>{{ item.name }}</span>
      </a-menu-item>
      <sub-menu v-else :itemValue="item" :key="item.id" />
    </template>
  </a-menu>
</template>
<script>
import { mapState } from 'vuex'
import { Icon } from 'ant-design-vue'
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_593618_u6qdf80xaeq.js' // 在 iconfont.cn 上生成
})
import subMenu from './sub-menu'
export default {
  data() {
    return {
      sideIndex: [], // 选择导航栏索引
      openKeys: [] // 展开导航栏索引
    }
  },
  computed: {
    ...mapState(['sidebar'])
  },
  components: {
    subMenu,
    MyIcon
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    menuSelect(e) {
      // 导航栏点击事件
      if (e) {
        // ui组件点击事件
        const data = {
          key: e.key,
          keyPath: e.keyPath,
          url: e.item.value
        }
        this.jump(data)
      } else {
        // 首页初始化调用
        let navPathData = window.sessionStorage.getItem('navPathData')
        if (navPathData) {
          navPathData = JSON.parse(navPathData)
          this.openKeys = navPathData.openKeys
          this.sideIndex = navPathData.sideIndex
        } else {
          this.openKeys = []
          this.sideIndex = ['1']
        }
      }
    },
    openChange(e) {
      // SubMenu 展开/关闭的回调
      this.openKeys = e
    },
    jump(e) {
      const routeData = this.$route
      if (routeData.path === e.url) return
      this.$router.replace(e.url)
      const navPathData = JSON.stringify({
        sideIndex: [e.key],
        openKeys: e.keyPath
      })
      window.sessionStorage.setItem('navPathData', navPathData)
    }
  }
}
</script>
