/**
 * api接口的统一出口
 */
// 接口
import api from './api'
// 导出接口
export default {
  install(Vue) {
    Vue.prototype.$axios = api
  }
}
