import Vue from 'vue'
import VueRouter from 'vue-router'
import state from '../store/state'
import Home from '../views/index/index.vue'

Vue.use(VueRouter)
/*
 * clickNum: Number 面包屑点击后退层数
 * keepAlive: Boolean  是否缓存页面（设为true，则缓存当前页面，切换页面不刷新）
 * pageLevel: 0  // 页面级数（用于判断前进后退）
 */

const children = [
  {
    path: '/',
    name: 'starter',
    meta: {
      fromPath: [
        {
          text: '启动台',
          disabled: true
        }
      ],
      current: ['未分配角色的账号'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(/* webpackChunkName: "starter" */ '../views/main/starter.vue')
  },
  {
    path: '/roleBasedAccessControl',
    name: 'roleBasedAccessControl',
    meta: {
      fromPath: [
        // {
        //   text: '权限管理',
        //   disabled: true
        // }
      ],
      current: ['角色管理'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "role-based-access-control" */ '../views/main/role-based-access-control.vue'
      )
  },
  {
    path: '/roleBasedMenuControl',
    name: 'roleBasedMenuControl',
    meta: {
      fromPath: [
        // {
        //   text: '开发配置',
        //   disabled: true
        // }
      ],
      current: ['菜单管理'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "role-based-menu-control" */ '../views/main/role-based-menu-control.vue'
      )
  },
  {
    path: '/roleBasedAccessControlSet',
    name: 'roleBasedAccessControlSet',
    meta: {
      fromPath: [
        // {
        //   text: '权限管理',
        //   disabled: true
        // },
        // {
        //   text: '角色管理',
        //   disabled: true
        // }
      ],
      current: ['权限设置'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "role-based-access-control-set" */ '../views/main/role-based-access-control-set.vue'
      )
  },
  {
    path: '/accountBusiness',
    name: 'accountBusiness',
    meta: {
      fromPath: [
        // {
        //   text: '权限管理',
        //   disabled: true
        // }
      ],
      current: ['中台业务账号'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "account-business" */ '../views/main/account-business.vue'
      )
  },
  {
    path: '/accountManagement',
    name: 'accountManagement',
    meta: {
      fromPath: [
        // {
        //   text: '权限管理',
        //   disabled: true
        // }
      ],
      current: ['中台管理账号'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "account-management" */ '../views/main/account-management.vue'
      )
  },
  {
    path: '/accountAdd',
    name: 'accountAdd',
    meta: {
      fromPath: [
        // {
        //   text: '权限管理',
        //   disabled: true
        // },
        // {
        //   text: '账号管理',
        //   disabled: true
        // }
      ],
      current: ['新增账号'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "account-add" */ '../views/main/account-add.vue'
      )
  },
  {
    path: '/businessConfig',
    name: 'businessConfig',
    meta: {
      fromPath: [
        // {
        //   text: '业务配置',
        //   disabled: true
        // }
      ],
      current: ['常驻业务线配置'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "business-config" */ '../views/main/business-config.vue'
      )
  },
  {
    path: '/businessConfigInfo',
    name: 'businessConfigInfo',
    meta: {
      fromPath: [
        // {
        //   text: '业务配置',
        //   disabled: true
        // },
        // {
        //   text: '常驻业务',
        //   disabled: true
        // }
      ],
      current: ['常驻业务详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "business-config-info" */ '../views/main/business-config-info.vue'
      )
  },
  {
    path: '/activityConfig',
    name: 'activityConfig',
    meta: {
      fromPath: [
        // {
        //   text: '业务配置',
        //   disabled: true
        // }
      ],
      current: ['支线业务线配置'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "activity-config" */ '../views/main/activity-config.vue'
      )
  },
  {
    path: '/activityConfigInfo',
    name: 'activityConfigInfo',
    meta: {
      fromPath: [
        // {
        //   text: '业务配置',
        //   disabled: true
        // },
        // {
        //   text: '常驻业务',
        //   disabled: true
        // }
      ],
      current: ['支线业务详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "activity-config-info" */ '../views/main/activity-config-info.vue'
      )
  },
  {
    path: '/area',
    name: 'area',
    meta: {
      fromPath: [
        // {
        //   text: '业务配置',
        //   disabled: true
        // }
      ],
      current: ['地区管理'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(/* webpackChunkName: "area" */ '../views/main/area.vue')
  },
  {
    path: '/accountType',
    name: 'accountType',
    meta: {
      fromPath: [
        // {
        //   text: '系统配置',
        //   disabled: true
        // }
      ],
      current: ['系统账号类型'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "account-type" */ '../views/main/account-type.vue'
      )
  },
  {
    path: '/accountStarConfig',
    name: 'accountStarConfig',
    meta: {
      fromPath: [
        // {
        //   text: '系统配置',
        //   disabled: true
        // },
        // {
        //   text: '系统账号类型',
        //   disabled: true
        // }
      ],
      current: ['角色星级配置'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "account-type" */ '../views/main/account-star-config.vue'
      )
  },
  {
    path: '/upload',
    name: 'upload',
    meta: {
      fromPath: [],
      current: ['上传视频-测试'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(/* webpackChunkName: "upload" */ '../views/main/upload.vue')
  }
]

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: children
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/index/login.vue')
  }
]
const permission = state.permission
permission.forEach((e) => {
  // 查找父级以及追加父级标题
  if (e.parent_id) {
    const forFn = function(val) {
      permission.forEach((item) => {
        if (item.id === val.parent_id) {
          val.fromPath = item.name
        }
      })
    }
    forFn(e)
  }
})
children.forEach((e) => {
  const item = permission.find((f) => f.url === e.path)
  if (item) {
    // 添加上级页面标题
    if (item.id !== 1) {
      e.meta.fromPath.push({
        text: item.fromPath,
        disabled: item.visible
      })
    }
    // 添加当前页标题
    e.meta.current = [item.name]
  }
})
;(function formatPurview() {
  // 格式化权限列表
  const array = {}
  const returnArr = []
  permission.forEach((item) => {
    array[item.id] = item
  })
  permission.forEach((item) => {
    if (item.visible) {
      if (item.parent_id !== 0) {
        ;(
          array[item.parent_id].children ||
          (array[item.parent_id].children = [])
        ).push(item)
      } else {
        returnArr.push(item)
      }
    }
  })
  state.sidebar = returnArr
})()

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
