import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './assets/less/reset.less'
import config from './utils/config'
import './utils/version-update'
import pictureUploadInspection from './utils/pictureUploadInspection'
import axios from './axios'
import permission from './utils/permission'
Vue.use(config)
Vue.use(Antd)
Vue.use(axios)
Vue.use(permission)

Vue.config.productionTip = false

const vue = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

Vue.use(pictureUploadInspection, vue)
