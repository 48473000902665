export default {
  token: '',
  qiniuToken: {},
  pageTitle: '',
  sidebar: [],
  permission: [
    {
      id: 1,
      name: '未分配角色的账号',
      url: '/',
      parent_id: 0,
      icon: null,
      visible: true
    },
    {
      id: 2,
      name: '账号/角色权限',
      url: '',
      parent_id: 0,
      icon: null,
      visible: true
    },
    {
      id: 21,
      name: '中台业务账号',
      url: '/accountBusiness',
      parent_id: 2,
      icon: null,
      visible: true
    },
    {
      id: 25,
      name: '中台管理账号',
      url: '/accountManagement',
      parent_id: 2,
      icon: null,
      visible: true
    },
    {
      id: 22,
      name: '新增账号',
      url: '/accountAdd',
      parent_id: 25,
      icon: null,
      visible: false
    },
    {
      id: 23,
      name: '中台角色权限',
      url: '/roleBasedAccessControl',
      parent_id: 2,
      icon: null,
      visible: true
    },
    {
      id: 24,
      name: '设置角色权限',
      url: '/roleBasedAccessControlSet',
      parent_id: 23,
      icon: null,
      visible: false
    },
    {
      id: 3,
      name: '业务配置',
      url: '',
      parent_id: 0,
      icon: null,
      visible: true
    },
    {
      id: 31,
      name: '常驻业务',
      url: '/businessConfig',
      parent_id: 3,
      icon: null,
      visible: true
    },
    {
      id: 32,
      name: '常驻业务详情',
      url: '/businessConfigInfo',
      parent_id: 31,
      icon: null,
      visible: false
    },
    {
      id: 33,
      name: '支线业务',
      url: '/activityConfig',
      parent_id: 3,
      icon: null,
      visible: true
    },
    {
      id: 34,
      name: '支线业务详情',
      url: '/activityConfigInfo',
      parent_id: 33,
      icon: null,
      visible: false
    },
    {
      id: 35,
      name: '地区管理',
      url: '/area',
      parent_id: 3,
      icon: null,
      visible: true
    },
    {
      id: 4,
      name: '开发配置',
      url: '',
      parent_id: 0,
      icon: null,
      visible: true
    },
    {
      id: 41,
      name: '微信开发配置',
      url: '',
      parent_id: 4,
      icon: null,
      visible: true
    },
    {
      id: 42,
      name: '中台菜单配置',
      url: '/roleBasedMenuControl',
      parent_id: 4,
      icon: null,
      visible: true
    },
    {
      id: 43,
      name: '系统日志',
      url: '',
      parent_id: 4,
      icon: null,
      visible: true
    },
    {
      id: 5,
      name: '系统配置',
      url: '',
      parent_id: 0,
      icon: null,
      visible: true
    },
    {
      id: 51,
      name: '系统账号类型',
      url: '/accountType',
      parent_id: 5,
      icon: null,
      visible: true
    },
    {
      id: 52,
      name: '角色星级配置',
      url: '/accountStarConfig',
      parent_id: 51,
      icon: null,
      visible: false
    }
  ]
}
