<template functional>
  <a-sub-menu :key="props.itemValue.id">
    <span slot="title">
      <my-icon type="icon-qingchu" /><span>{{ props.itemValue.name }}</span>
    </span>
    <template v-for="item in props.itemValue.children">
      <sub-menu v-if="item.children" :key="item.id" :item-value="item" />
      <a-menu-item v-else :key="item.id" :value="item.url">
        {{ item.name }}
      </a-menu-item>
    </template>
  </a-sub-menu>
</template>
<script>
import { Icon } from 'ant-design-vue'
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_593618_u6qdf80xaeq.js' // 在 iconfont.cn 上生成
})
export default {
  props: {
    itemValue: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  components: {
    MyIcon
  }
}
</script>
