<template>
  <a-layout>
    <a-layout-sider width="208" collapsedWidth="48" v-model="collapsed">
      <div class="logo" :class="{ 'logo-width-collapsed': collapsed }">
        <img
          class="logo-icon logo-icon-2"
          src="../../assets/image/logo-2.png"
          alt="logo"
          v-if="collapsed"
        />
        <img
          class="logo-icon logo-icon-1"
          src="../../assets/image/logo-1.png"
          alt="logo"
          v-else
        />
      </div>
      <nav-menu ref="navMenu" :collapsed="collapsed" />
      <a-button class="collapsed-btn" type="link" @click="toggleCollapsed">
        <a-icon
          class="sider-btn-icon"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
        />
      </a-button>
    </a-layout-sider>
    <a-layout-content>
      <a-layout-header>
        <div class="btn-box">
          <!-- <a-button type="link">
            <a-icon class="btn-icon" type="search" />
          </a-button>
          <a-button type="link">
            <a-icon class="btn-icon" type="bell" />
          </a-button> -->
          <div class="out" @click="outClick">
            <div class="user">
              <img
                class="avatar"
                src="../../assets/image/avatar.png"
                alt="avatar"
              />
              <span class="user-name">{{ name }}</span>
            </div>
          </div>
        </div>
      </a-layout-header>
      <a-breadcrumb separator=">" v-if="pathData.length">
        <a-breadcrumb-item v-for="(item, index) in pathData" :key="index">
          <span
            :class="{ 'breadcrumb-link': !item.disabled }"
            @click="backClick(index, item.disabled)"
            >{{ item.text }}</span
          >
        </a-breadcrumb-item>
        <a-breadcrumb-item v-if="currentPathName">
          {{ currentPathName }}
        </a-breadcrumb-item>
      </a-breadcrumb>

      <div class="main-wrap">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      </div>
    </a-layout-content>

    <div class="system" v-if="isOutBtn">
      <div class="user">
        <div class="name">
          阿六到家（后台）
        </div>
        <div class="item">
          <span class="label">登录账号：</span>
          <span class="value">{{ account }}</span>
        </div>
        <div class="item">
          <span class="label">账号类型：</span>
          <span class="value">{{ userType }}</span>
        </div>
      </div>
      <div class="btns" @click="out">
        注销登录
      </div>
    </div>
    <a-modal
      v-model="visible"
      title="修改密码"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancelModal"
      @ok="okModal"
    >
      <a-form :form="form" layout="vertical">
        <a-form-item label="原密码">
          <a-input-password
            placeholder="请输入原密码"
            v-decorator="[
              'oldPassword',
              {
                rules: [{ required: true, message: '请输入原密码' }]
              }
            ]"
          />
        </a-form-item>
        <a-form-item label="新密码">
          <a-input-password
            placeholder="请输入新密码"
            v-decorator="[
              'newPassword',
              {
                rules: [{ required: true, message: '请输入新密码' }]
              }
            ]"
          />
        </a-form-item>
        <a-form-item label="确认新密码">
          <a-input-password
            placeholder="请再次输入新密码"
            v-decorator="[
              'comfigPassword',
              {
                rules: [{ required: true, message: '请再次输入新密码' }]
              }
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout>
</template>
<script>
import { mapState } from 'vuex'
import navMenu from '@/components/nav-menu/index'
export default {
  data() {
    return {
      name: '超级管理员', // 账号名称
      account: 'Admin', // 账号
      userType: '超级管理员', // 账号类型
      mechanism: '', // 账号机构
      visible: false,
      form: this.$form.createForm(this),
      isOutBtn: false, // 是否显示账号操作
      collapsed: false, // 展开、收起二级导航栏
      pathData: [], // 面包屑
      currentPathName: '', // 当前路径名称
      clickNum: 0 // 面包屑可点击个数
    }
  },
  computed: {
    ...mapState(['token'])
  },
  components: {
    navMenu
  },
  created() {
    // this.name = window.sessionStorage.getItem('name')
  },
  mounted() {
    this.$refs.navMenu.menuSelect()
  },
  methods: {
    toggleCollapsed() {
      // 展开、收起二级导航栏
      this.collapsed = !this.collapsed
    },
    outClick() {
      // 账号点击事件
      this.isOutBtn = !this.isOutBtn
    },
    openModal() {
      this.visible = true
      this.isOutBtn = false
    },
    cancelModal() {
      // 取消修改密码
      this.visible = false
      this.form.resetFields()
    },
    okModal() {
      // 确认修改密码
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values)
          this.$message.success('操作成功')
          this.cancelModal()
        }
      })
    },
    out() {
      // 注销确认框
      this.$confirm({
        title: '注销',
        content: '确认注销登录？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          window.sessionStorage.clear()
          window.location.replace('/login')
        }
      })
    },
    setBreadcrumb(to, from, init) {
      const toMeta = to.meta
      const fromMeta = from.meta
      // console.log(toMeta)
      if (!toMeta.fromPath) return
      if (toMeta.fromPath.length) {
        // 当前路径有上级路径
        if (init) {
          // 刷新时，获取缓存的数据
          let pathData = window.sessionStorage.getItem('pathData')
          if (pathData) {
            pathData = JSON.parse(pathData)
            if (toMeta.pageLevel) {
              // 内页时赋值缓存的数据
              toMeta.current.forEach((f) => {
                // 查找是否有跟当前页面重复的，有则删除
                const i = pathData.findIndex((e) => e.text === f)
                if (i > -1) {
                  pathData.splice(i, 1)
                }
              })
              this.pathData = pathData
              window.sessionStorage.setItem(
                'pathData',
                JSON.stringify(pathData)
              )
            } else {
              // 非内页，直接用路由信息的数据
              this.pathData = toMeta.fromPath
            }
          } else {
            this.pathData = toMeta.fromPath
          }
        } else {
          if (toMeta.pageLevel) {
            if (fromMeta.pageLevel > toMeta.pageLevel) {
              // 返回上一页时，删除最后一个数据后再赋值
              let pathData = window.sessionStorage.getItem('pathData')
              pathData = JSON.parse(pathData)
              pathData.splice(pathData.length - 1, 1)
              this.pathData = pathData
              window.sessionStorage.setItem(
                'pathData',
                JSON.stringify(pathData)
              )
            } else if (fromMeta.pageLevel !== toMeta.pageLevel) {
              // 前进时，跟前面的页面数据合并
              this.pathData = [...this.pathData, ...toMeta.fromPath]
              window.sessionStorage.setItem(
                'pathData',
                JSON.stringify(this.pathData)
              )
            }
          } else {
            // 非子页面，直接用路由信息的数据
            this.pathData = toMeta.fromPath
          }
        }

        if (to.query.id) {
          // 当前页面是更新页面
          if (toMeta.current.length === 2) {
            this.currentPathName = toMeta.current[1]
          } else {
            this.currentPathName = toMeta.current[0]
          }
        } else {
          this.currentPathName = toMeta.current[0]
        }
      } else {
        this.pathData = []
      }
    },
    backClick(i, d) {
      // 面包屑跳转
      if (d) return
      const pathNum = this.pathData.length - i
      this.$router.go(-pathNum)
    },
    onChangePageTitle(to) {
      let currentTitle = ''
      if (to.query.type === 'edit') {
        currentTitle = to.meta.current[1]
      } else {
        currentTitle = to.meta.current[0]
      }
      this.$store.commit('changePageTitle', currentTitle)
    }
  },
  beforeRouteEnter(to, from, next) {
    // 监听路由跳转
    const token = window.sessionStorage.getItem('token')
    if (token) {
      next((vm) => {
        vm.onChangePageTitle(to)
        if (to.meta.buttons) {
          vm.setPageButtons(to.meta.buttons)
        }
        vm.setBreadcrumb(to, from, 'init')
      })
    } else {
      next('/login')
    }
  },
  beforeRouteUpdate(to, from, next) {
    // 监听路由变化
    this.onChangePageTitle(to)
    if (to.meta.pageLevel) {
      // 跳转内页
      if (
        to.meta.pageLevel === 1 &&
        from.meta.pageLevel === 0 &&
        !from.meta.keepAlive
      ) {
        from.meta.keepAlive = true // 上级缓存页面
      }
      if (to.meta.pageLevel > 1 && from.meta.keepAlive) {
        from.meta.keepAlive = false // 上级不缓存页面
      }
    } else {
      // 非跳转内页
      if (from.meta.keepAlive) {
        // 上一个页面缓存时，设置非缓存
        from.meta.keepAlive = false
      }
    }
    // console.log(from.meta.keepAlive, to.meta.keepAlive)
    if (to.meta.buttons) {
      this.setPageButtons(to.meta.buttons)
    }
    this.setBreadcrumb(to, from)
    next()
  }
}
</script>
<style scoped>
.ant-layout {
  height: 100vh;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-layout-header {
  height: 46px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
  z-index: 99;
}

.ant-layout-header .btn-box {
  display: flex;
  align-items: center;
}

.ant-menu {
  flex: 1;
}

.logo {
  width: 208px;
  height: 46px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #111d2c;
}

.logo-width-collapsed {
  width: 46px;
}

.logo-icon {
  height: 22px;
  margin: 0 auto;
}

.logo-icon-1 {
  width: 133px;
}

.logo-icon-2 {
  width: 18px;
}

h1 {
  width: 100%;
  margin: 0;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 16px;
}

.btn-icon {
  font-size: 20px;
  color: #111d2c;
}

.out {
  margin-right: 25px;
}

.out .user {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #111d2c;
  margin-left: 15px;
}

.out .avatar {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.out .user-name {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}

.ant-layout-sider >>> .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.ant-menu {
  flex: 1;
}

.collapsed-btn {
  text-align: left;
}

.sider-btn-icon {
  flex-shrink: 1;
  font-size: 20px;
  color: #fff;
}

.system {
  width: 330px;
  position: fixed;
  top: 54px;
  right: 24px;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 9;
  border-radius: 4px;
  box-shadow: 0px 4px 16px 0px rgba(0, 21, 41, 0.1);
}

.system .name {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 12px;
}

.system .user {
  padding: 24px;
  font-size: 14px;
  line-height: 22px;
}

.system .user .item:not(:last-child) {
  margin-bottom: 8px;
}

.system .label {
  color: rgba(0, 0, 0, 0.85);
}

.system .value {
  color: rgba(0, 0, 0, 0.65);
}

.system .btns {
  background-color: #f7f9fa;
  border: 1px solid #e9e9e9;
}

.system .btns {
  height: 48px;
  cursor: pointer;
  text-align: center;
  line-height: 48px;
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.45);
  border-radius: 0 0 4px 4px;
}

.ant-layout-content {
  height: 100vh;
  display: flex;
  flex-flow: column;
}

.ant-breadcrumb {
  width: 100%;
  flex: 0 0 54px;
  background-color: #fff;
  line-height: 54px;
  padding: 0 24px;
  box-sizing: border-box;
}

.main-wrap {
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}

@media screen and (max-width: 1080px) {
  .main-wrap {
    overflow-x: auto;
  }
}
</style>
<style>
a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

p {
  margin: 0;
}

.ant-breadcrumb .breadcrumb-link {
  cursor: pointer;
}

.ant-breadcrumb .breadcrumb-link:hover {
  color: #1890ff;
}

.pages {
  min-height: 100%;
  min-width: 1080px;
}

.ant-tabs {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.ant-tabs-content {
  flex: 1;
  overflow: hidden;
}

.container .ant-page-header {
  padding: 0 24px 16px 24px;
}

.container .ant-page-header,
.btn-wrap,
.main-wrap .ant-tabs-bar,
.ant-table-wrapper {
  background-color: #fff;
}

.ant-page-header-heading-extra {
  display: flex;
}

.ant-tabs-nav-container {
  padding: 0 16px;
}

.main-wrap .ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tabpane,
.ant-tabs-tabpane .main-content {
  height: 100%;
}

.main-content {
  flex: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

/* 搜索框-start */
.ribbon-wrap {
  background-color: #fff;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.ribbon-wrap .search-box,
.ribbon-wrap .btn-box {
  display: flex;
  align-items: center;
}

.ribbon-wrap .search-input {
  width: 180px;
  height: 32px;
}

.ribbon-wrap .btn-box .ant-btn:not(:last-child) {
  margin-right: 8px;
}
/* 搜索框-end */

.btn-wrap {
  padding: 24px 24px 0 24px;
}

.main-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 32px 0 32px;
  background-color: #fff;
}

.main-content-header .title,
.main-content-header .title-1 {
  color: #333;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.main-content-header .title-2 {
  color: #8c8c8c;
}

.main-content-header .item .ant-btn:not(:last-child) {
  margin-right: 8px;
}

.table-wrap {
  flex: 1;
  padding: 24px 32px 32px 32px;
  background-color: #fff;
  border-radius: 2px;
}

.statistic-inline-block .ant-statistic-content,
.statistic-inline-block .ant-statistic-content-value-decimal,
.table-wrap .ant-statistic-content,
.table-wrap .ant-statistic-content-value-decimal,
.tabs-table .ant-statistic-content,
.tabs-table .ant-statistic-content-value-decimal,
.general-charges .ant-statistic-content,
.general-charges .ant-statistic-content-value-decimal {
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}

.general-charges .ant-statistic {
  display: inline-block;
}

.main-content .ant-table-thead > tr > th,
.main-content .ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.main-content .ant-table-pagination.ant-pagination {
  float: left;
}

.main-content .ant-table-pagination.ant-pagination {
  margin: 16px 0 0 0;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.form-item {
  display: flex;
  justify-content: space-between;
}

.form-item .ant-form-item {
  width: calc(50% - 8px);
}

.form-item-border-top {
  border-top: 1px dashed rgba(151, 151, 151, 0.2);
  padding-top: 32px;
}

.IDcard-uploader .ant-upload.ant-upload-select-picture-card {
  width: 188px;
  height: 120px;
}

.main-content
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  white-space: pre;
}

.ant-modal .ant-modal-close-x {
  font-size: 26px;
}

/* 通用工程单详情-start */
.general-info {
  background-color: #fff;
  padding: 24px;
  border-radius: 2px;
}

.general-info-2 {
  padding: 24px 24px 8px 24px;
}

.general-info-title {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.general-info-item {
  overflow: hidden;
  margin: 16px 0 8px 0;
}

.general-info-item-2 {
  margin: 16px 0 0 0;
}

.general-info-item .item-text {
  min-width: 270px;
  max-width: 270px;
  line-height: 22px;
  margin-bottom: 16px;
  display: inline-flex;
  vertical-align: top;
}

.general-info-item .item-text:not(:last-child) {
  margin-right: 16px;
}

.general-info-item .item-label {
  color: #333;
  flex-shrink: 0;
}

.general-info-item .item-label-code {
  color: rgba(51, 51, 51, 0.35);
}

.general-contract .btns {
  margin: 8px 0 0 16px;
}

.general-contract {
  display: flex;
  padding-top: 24px;
  border-top: 1px dashed rgba(151, 151, 151, 0.2);
}

.general-contract .item-text:not(:last-child) {
  margin-right: 72px;
}

.general-contract .item-icon {
  margin-right: 3px;
}

.general-contract .ant-btn {
  padding: 0;
  margin-right: 16px;
}
/* 通用工程单详情-end */
/* 通用应收款、劳务费详情-start */
.general-charges {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.general-charges .title-wrap {
  display: flex;
  justify-content: space-between;
}

.general-charges .title {
  font-size: 16px;
  font-weight: bold;
  color: #262626;
  margin-bottom: 16px;
}

.general-charges .charges-item {
  width: calc(50% - 8px);
  background-color: #fff;
  border-radius: 2px;
  padding: 24px;
}

.general-charges .charges-item-2 {
  padding: 24px 24px 8px 24px;
}

.general-charges .charges-item-100 {
  width: 100%;
}

.general-charges .charges-text {
  overflow: hidden;
}

.general-charges .item-text {
  min-width: 270px;
  max-width: 270px;
  margin-bottom: 16px;
  display: inline-flex;
  vertical-align: top;
}

.general-charges .item-text:not(:last-child) {
  margin-right: 16px;
}

.general-charges .item-label {
  flex-shrink: 0;
  color: #333;
}

.general-charges .item-label-code {
  color: rgba(51, 51, 51, 0.35);
}

.general-charges .btns {
  margin-top: 8px;
}

.general-charges .item:not(:last-child) {
  margin-bottom: 16px;
}

.general-charges .certificate {
  margin-top: 8px;
  padding-top: 24px;
  border-top: 1px dashed rgba(151, 151, 151, 0.2);
}
/* 通用应收款、劳务费详情-end */

.statistic-inline-block {
  line-height: 20px;
  display: inline-block;
}

.inner-table {
  background-color: #fafafa;
}
</style>
